import { lazy, Suspense } from "react";

const PLayout = lazy(() => import("../components/PLayout/index"));
const Login = lazy(() => import("../pages/User/login"));
const Home = lazy(() => import("../pages/Home/index"));

const PdbTool = lazy(() => import("../pages/Tool/pdb"));
const ElectronBuild = lazy(() => import("../pages/Tool/electron_build"));
const Alph = lazy(() => import("../pages/Tool/alp"));

const ProjectCreate = lazy(() => import("../pages/Project/create"));
const ProjectCutedgeOptCreate = lazy(() =>
  import("../pages/Project/cutedgeopt_create")
);

const ProjectReCreate = lazy(() => import("../pages/Project/recreate"));

const ProjectList = lazy(() => import("../pages/Project/list"));

const lazyLoad = (children) => {
  return <Suspense fallback={<h1>Loading...</h1>}>{children}</Suspense>;
};

export const routers = [
  {
    path: "/",
    element: lazyLoad(<PLayout />),
    children: [
      {
        path: "/home",
        element: lazyLoad(<Home />),
      },
      {
        path: "/project/list",
        element: lazyLoad(<ProjectList />),
      },
      {
        path: "/project/create",
        element: lazyLoad(<ProjectCreate />),
      },
      {
        path: "/project/recreate",
        element: lazyLoad(<ProjectReCreate />),
      },
      {
        path: "/project/cutedgeopt_create",
        element: lazyLoad(<ProjectCutedgeOptCreate />),
      },
      {
        path: "/tool/pdb",
        element: lazyLoad(<PdbTool />),
      },
      {
        path: "/tool/electron_build",
        element: lazyLoad(<ElectronBuild />),
      },
      {
        path: "/tool/alp3",
        element: lazyLoad(<Alph />),
      },
    ],
  },
  // {
  //   path: "/project/create",
  //   element: lazyLoad(<ProjectCreate />),
  // },
  {
    path: "/login",
    element: lazyLoad(<Login />),
  },
];
